// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';



// Your web app's Firebase configuration
const firebaseConfig = {

  apiKey: "AIzaSyDv1tQKuM5U_k0X6y0yE-atAKYJt27J_Xk",

  authDomain: "hflu-a8535.firebaseapp.com",

  databaseURL: "https://hflu-a8535-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "hflu-a8535",

  storageBucket: "hflu-a8535.appspot.com",

  messagingSenderId: "285652870951",

  appId: "1:285652870951:web:8b881a6a758ec7b4de2b02"


};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a Firestore instance
export const db = getFirestore(app);

// Get an auth instance
export const auth = getAuth(app);


