import { lineupOfficial, TOTAL_SUB_CANVAS, ROWS_PER_CANVAS, TIER_DEFAULT, POINTS_EXISTS, POINTS_GOOD_STAGE, POINTS_GOOD_DAY, POINTS_GOOD_HOUR, POINTS_ALL_GOOD,selectedMoreThanOnce,POINTS_BAND_ALONE } from './constants';
import { getDatabase, ref, set, get } from "firebase/database";
import { fetchCanvasBandsData,updateUserSingleBonus,setSingleBonus,setUserPoints } from './FirebaseUtils';




const createOfficialBandsMapping = (officialCanvasBands) => {
    const mapping = {};

    for (let row = 0; row < TOTAL_SUB_CANVAS; row++) {
        for (let col = 0; col < officialCanvasBands[row].length; col++) {
            const bandName = officialCanvasBands[row][col].name;
            mapping[bandName] = {
                row: Math.floor(row / ROWS_PER_CANVAS),
                column: row % ROWS_PER_CANVAS,
                position: col
            };
        }
    }
    return mapping;
};



const calculateUserPoints = async (userCanvasBands, officialBandsMapping, userId, db,selectedBands) => {
    let userPoints = 0;
    const updatePromises = [];
    const groupedStages = {
        1: new Set([1, 2]),
        2: new Set([1, 2]),
        5: new Set([5, 6]),
        6: new Set([5, 6])
    };

    for (let i = 0; i < TOTAL_SUB_CANVAS; i++) {
        for (let j = 0; j < userCanvasBands[i].length; j++) {
            const userBand = userCanvasBands[i][j].name;
            let bandScore = 0;
            let tier = TIER_DEFAULT;
            if (officialBandsMapping[userBand]) {
                bandScore = POINTS_EXISTS;
                tier++;

                if (selectedBands[userBand]) {
                    selectedBands[userBand] = selectedMoreThanOnce;
                } else {
                    selectedBands[userBand] = userId;
                }

                const realStage = officialBandsMapping[userBand].row
                const bandStage = Math.floor(i / ROWS_PER_CANVAS)
                if (groupedStages[realStage]?.has(bandStage) || realStage === bandStage) {
                    bandScore += POINTS_GOOD_STAGE;
                    tier++;
                    if (realStage === bandStage){
                        bandScore += POINTS_GOOD_STAGE;
                        tier++;
                    } 
                }
                if (i % ROWS_PER_CANVAS === officialBandsMapping[userBand].column) {
                    bandScore += POINTS_GOOD_DAY;
                    tier++
                }
                if (officialBandsMapping[userBand].position === j) {
                    bandScore += POINTS_GOOD_HOUR;
                    tier++
                }
                if (Math.floor(i / ROWS_PER_CANVAS) === officialBandsMapping[userBand].row &&
                    i % ROWS_PER_CANVAS === officialBandsMapping[userBand].column &&
                    officialBandsMapping[userBand].position === j) {
                    bandScore += POINTS_ALL_GOOD;
                }
            }

            userPoints += bandScore;

            // Update band tier in Firebase
            const updateBandTierPromise = set(ref(db, `users/${userId}/subcanvas${i}/spot${j}`), {
                band: userBand,
                tier: tier
            });
            updatePromises.push(updateBandTierPromise);
        }
    }

    await Promise.all(updatePromises);

    return userPoints;
};

const resetBandsTier = async (userCanvasBands, userId, db) => {
    const updatePromises = [];

    for (let i = 0; i < TOTAL_SUB_CANVAS; i++) {
        for (let j = 0; j < userCanvasBands[i].length; j++) {
            const userBand = userCanvasBands[i][j].name;
            let tier = TIER_DEFAULT;
            // Update band tier in Firebase
            const updateBandTierPromise = set(ref(db, `users/${userId}/subcanvas${i}/spot${j}`), {
                band: userBand,
                tier: tier
            });
            updatePromises.push(updateBandTierPromise);
        }
    }

    await Promise.all(updatePromises);

};

export const CalculateScores = async (initialBands) => {
    const db = getDatabase();
    const selectedBands = {};

    // Fetching official lineup
    const officialData = await fetchCanvasBandsData(lineupOfficial, initialBands);
    const officialCanvasBands = officialData.newCanvasBands;
    const officialBandsMapping = createOfficialBandsMapping(officialCanvasBands);

    const usersRef = ref(db, 'users');
    const usersSnapshot = await get(usersRef);
    const usersData = usersSnapshot.val();
    const updatePromises = [];

    for (let userId in usersData) {
        // Fetching bands for each user
        if (userId === lineupOfficial) continue
        setSingleBonus(userId,0);
        const userData = await fetchCanvasBandsData(userId, initialBands);
        let userCanvasBands = userData.newCanvasBands;

        // Calculate points and update tiers
        const userPoints = await calculateUserPoints(userCanvasBands, officialBandsMapping, userId, db,selectedBands);

        // Update user points in Firebase
        const updateUserPointsPromise = set(ref(db, `users/${userId}/points`), userPoints);
        //const updateUserPointsPromise = set(ref(db, `users/${userId}/points`), 0);
        updatePromises.push(updateUserPointsPromise);
    }

    for (let band in selectedBands) {
        if (selectedBands[band] !== selectedMoreThanOnce) {
            
          // This band was uniquely selected by a user, grant them the bonus points
          const uniqueUserId = selectedBands[band];
          //console.log(band +" : "+uniqueUserId )
          updateUserSingleBonus(uniqueUserId,POINTS_BAND_ALONE);       
        }
      }

    // Await all updates to complete
    return Promise.all(updatePromises);
};

export const ResetScores = async (initialBands) => {
    const db = getDatabase();
    const selectedBands = {};

    // Fetching official lineup
    const officialData = await fetchCanvasBandsData(lineupOfficial, initialBands);
    const officialCanvasBands = officialData.newCanvasBands;
    const officialBandsMapping = createOfficialBandsMapping(officialCanvasBands);

    const usersRef = ref(db, 'users');
    const usersSnapshot = await get(usersRef);
    const usersData = usersSnapshot.val();
    const updatePromises = [];

    for (let userId in usersData) {
        // Fetching bands for each user
        if (userId === lineupOfficial) continue
        setSingleBonus(userId,0);
        setUserPoints(userId,0)
        const userData = await fetchCanvasBandsData(userId, initialBands);
        let userCanvasBands = userData.newCanvasBands;

        // Calculate points and update tiers
        await resetBandsTier(userCanvasBands, userId, db);

    }



    return Promise.all(updatePromises);
};


