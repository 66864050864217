// AvailableBands.js
import React, { useState, useEffect } from 'react';
import PaginationButtons from './PaginationButtons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { MaxBandsPerPage,MinBandsPerPage,bandBoxSize,MarginOffset } from './constants';
import { isMobile } from 'react-device-detect';
import { handleAddBand } from './FirebaseUtils'


export let filteredBands;


const AvailableBands = ({ bands, searchTerm, filter, setFilter, setSearchTerm, setNewBandName, setCurrentPage, currentPage, currentUser, selectedUser, isAdminMode, setBands, initialBands, setCanvasBands, newBandName, setIsAdminMode, setInitialBands, lineupModificationAllowed,setFeedback }) => {
  const { t } = useTranslation();

  const [availableHeight, setAvailableHeight] = useState(window.innerHeight);
  const zoomLevel = 1; // Adjust this dynamically based on your design.
  const bandsPerPage = Math.max(MinBandsPerPage,Math.min(MaxBandsPerPage, Math.floor((availableHeight - MarginOffset * zoomLevel) / (bandBoxSize * zoomLevel))));
  // Listen for resize events
  useEffect(() => {
    const handleResize = () => {
      setAvailableHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  //const bandsPerPage = 50;
  const startIndex = (currentPage - 1) * bandsPerPage;
  const endIndex = startIndex + bandsPerPage;

  if (filter === 'all') {
    filteredBands = bands;
  } else if (filter === '0-9') {
    filteredBands = bands.filter((band) => !isNaN(band.name[0]));
  } else {
    filteredBands = bands.filter((band) => band.name[0].toLowerCase() === filter.toLowerCase());
  }



  // Filter bands by search term before slicing
  const searchTermFilteredBands = filteredBands
    .filter((band) => band.name.toLowerCase().startsWith(searchTerm.toLowerCase()));

  // Slice the searchTermFilteredBands for pagination
  const displayedBands = searchTermFilteredBands.slice(startIndex, endIndex);
  const totalPages = Math.ceil(searchTermFilteredBands.length / bandsPerPage);

  const uniqueFirstLetters = ['all', ...new Set(bands.map((band) => {
    const firstChar = band.name[0].toLowerCase();
    if (isNaN(firstChar)) {
      return firstChar;
    } else {
      return '0-9';
    }
  }))];

  return (
    <div className="available-bands-container">
      <div className="sticky-wrapper">
        <h2>{t('availableBands.availableBandsTitle')}</h2>

        <div className="search-and-add-wrapper">
          <input
            type="text"
            size="30"
            className="search-input"
            placeholder={t('availableBands.searchBandsPlaceholder')}
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
              setNewBandName(event.target.value);
              setFilter('all');
              setCurrentPage(1);
            }}
          />

          {searchTerm && (
            <button
              className="add-band-button"
              onClick={async () => {
                const bandAdded = await handleAddBand(setBands, initialBands, setInitialBands, newBandName, setIsAdminMode,setFeedback,t);
                if (bandAdded) {
                  //console.log(bandAdded)
                } else {
                  setSearchTerm('');
                }
              }}
            >
              Add Band
            </button>
          )}
        </div>

        {isMobile ? (
          <select onChange={(e) => {
            setFilter(e.target.value);
            setSearchTerm('');
            setCurrentPage(1);
          }}>
            {uniqueFirstLetters.map((letter, index) => (
              <option key={index} value={letter}>
                {letter.toUpperCase()}
              </option>
            ))}
          </select>
        ) : (
          <div className="filter-buttons">
            {uniqueFirstLetters.map((letter, index) => (
              <button
                key={index}
                onClick={() => {
                  setFilter(letter);
                  setSearchTerm('');
                  setCurrentPage(1);
                }}
                className={`filter-button ${filter === letter ? 'active' : ''}`}
              >
                {letter.toUpperCase()}
              </button>
            ))}
          </div>
        )}

        <div className="band-list-container">
          <PaginationButtons currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
          <div className="pagination-text">
          {totalPages !== 0 ? `${currentPage} / ${totalPages}` : ""}
          </div>

          <Droppable droppableId="availableBands">
            {(provided) => (
              <div>
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="bands-list"
                >
                  {displayedBands
                    .filter((band) => band.name.toLowerCase().startsWith(searchTerm.toLowerCase()))
                    .map((band, index) => (
                      <Draggable
                        key={`available-${band.id}`}
                        draggableId={`available-${band.id}`}
                        index={startIndex + index}
                        isDragDisabled={(!lineupModificationAllowed || currentUser.displayName !== selectedUser) && !isAdminMode}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`band-item ${((!lineupModificationAllowed || currentUser.displayName !== selectedUser) && !isAdminMode) ? 'band-item-disabled' : ''}`}
                          >
                            {band.name}
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ul>
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );


};


export default AvailableBands;