import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { COLUMNS_PER_CANVAS, ROWS_PER_CANVAS, TIER_ONE, TIER_TWO, TIER_THREE, TIER_FOUR, TIER_FIVE } from './constants';
import { useTranslation } from 'react-i18next';
import { handleRandomFill, clearLineupForUser } from './HooksAndHelpers';

function UserDropdown({ userList, onSelectUser, selectedUser }) {
  return (
    <select value={selectedUser} className="user-dropdown" onChange={e => onSelectUser(e.target.value)}>
      {userList.map((user, index) => (
        <option
          key={user.id}
          value={user.id}
          className={index === 0 ? "first-option" : index === 1 ? "second-option" : ""}
        >
          {user.name}
        </option>
      ))}
    </select>
  );
}


const tierToClass = (band) => {
  switch (band.tier) {
    case TIER_ONE: return 'tier-one';
    case TIER_TWO: return 'tier-two';
    case TIER_THREE: return 'tier-three';
    case TIER_FOUR: return 'tier-four';
    case TIER_FIVE: return 'tier-five';
    default: return '';
  }
};

const MemoizedBand = React.memo(function Band({ band, provided, snapshot, isSubCanvasFull }) {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={`band-item-in-canvas ${tierToClass(band)} ${isSubCanvasFull && snapshot.isDraggingOver ? 'dragging-over' : ''}`}
    >
      {band.name}
    </div>
  );
});


const Lineup = ({ UserList, setSelectedUser, canvasSize, canvasImage, canvasBands, currentUser, selectedUser, isAdminMode, lineupModificationAllowed, bands, setBands, setCanvasBands, setFeedback, initialBands, setFullSubCanvases, fullSubCanvases }) => {
  const headerHeight = canvasSize.height * 0.18;
  const CanvaSrightBorder = 60;
  const CanvasLeftBorder = 30;
  const subCanvasBorder = 30;
  const subcanvasBotBorder = 10;
  const subCanvaWidth = (canvasSize.width - CanvaSrightBorder - subCanvasBorder - CanvasLeftBorder) * 0.25;
  const RowHeight = (canvasSize.height - headerHeight) / COLUMNS_PER_CANVAS - subcanvasBotBorder;
  const { t } = useTranslation();

  const handleRandomFillClick = () => {
    const [newBands, newCanvasBands] = handleRandomFill(bands, canvasBands, selectedUser, setFeedback, t,setFullSubCanvases);
    setBands(newBands);
    setCanvasBands(newCanvasBands);
  };

  const handleClearLineup = () => {
    clearLineupForUser(selectedUser, setCanvasBands, setFeedback, t)
    setBands([...initialBands])
  }

  return (
    <div className="lineup-container">
      <h2>{t('lineup.title')}</h2>
      <div className="dropdown-button-container">
        <UserDropdown userList={UserList} onSelectUser={setSelectedUser} />
        <button
          className="random-fill-button"
          onClick={handleRandomFillClick}
          disabled={(!lineupModificationAllowed || currentUser.displayName !== selectedUser) && !isAdminMode}>
          {t('availableBands.randomFill')}
        </button>
        <button
          className="random-fill-button"
          onClick={handleClearLineup}
          disabled={(!lineupModificationAllowed || currentUser.displayName !== selectedUser) && !isAdminMode}>
          {t('availableBands.clearLineUp')}
        </button>
      </div>

      <div
        className="canvas"
        style={{
          width: `${canvasSize.width}px`,
          height: `${canvasSize.height}px`,
          backgroundImage: `url(${canvasImage})`,
        }}
      >
        {canvasBands.map((subCanvasBands, subCanvasIndex) => (
          <Droppable droppableId={`subcanvas-${subCanvasIndex}`} key={`subcanvas-${subCanvasIndex}`}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`sub-canvas ${snapshot.isDraggingOver && !fullSubCanvases[subCanvasIndex] ? 'dragging-over' : ''}`}
                style={{
                  width: `${subCanvaWidth}px`,
                  height: `${RowHeight}px`,
                  left: `${CanvasLeftBorder + (subCanvasIndex % ROWS_PER_CANVAS) * ((canvasSize.width - CanvaSrightBorder) / ROWS_PER_CANVAS)}px`,
                  top: `${headerHeight + Math.floor(subCanvasIndex / ROWS_PER_CANVAS) * (RowHeight + subcanvasBotBorder)}px`,
                  border: lineupModificationAllowed ? '' : 'none',
                }}
              >
                {subCanvasBands.map((band, index) => (
                  <Draggable
                    key={band.id}
                    draggableId={band.id}
                    index={index}
                    isDragDisabled={(!lineupModificationAllowed || currentUser.displayName !== selectedUser) && !isAdminMode}
                  >
                    {(provided, snapshot) => <MemoizedBand band={band} provided={provided} snapshot={snapshot} isSubCanvasFull={fullSubCanvases[subCanvasIndex]} />}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </div>
  );
};

export default Lineup;
