import React from 'react';
import { auth } from "./firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import './SignIn.css';
import googleLogo from './resources/google.png';
import { useTranslation } from 'react-i18next'; 

export function SignIn() {
    const { t } = useTranslation();

    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider);
    };

    return (
        <div className="signin-container">
            <h2>{t('welcomeToMyApp')}</h2>
            <Instructions />
            <button className="google-signin" onClick={signInWithGoogle}>
                <img src={googleLogo} alt="Google Logo" />
                {t('signInWithGoogle')}
            </button>
        </div>
    );
}

function Instructions() {
  const { t } = useTranslation();

  return (
      <div className="instructions">          
          <p>
              {t('signInInstruction')}
          </p>
      </div>
  );
}

export const handleSignOut = () => {
    auth.signOut()
        .then(() => {
            console.log('User signed out successfully');
        })
        .catch(error => {
            console.error('Error signing out:', error);
        });
};
