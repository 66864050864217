import { set, ref, getDatabase } from "firebase/database";
import {importOfficialLineUp} from './HooksAndHelpers';
import {CalculateScores,ResetScores} from './Calculatescores';
import { useTranslation } from 'react-i18next';

const AdminControl = ({ isAdmin, setIsAdminMode, lineupModificationAllowed, setLineupModificationAllowed, initialBands,canvasBands,setCanvasBands,setFeedback,bands,setBands,setFullSubCanvases,setInitialBands}) => {
    const db = getDatabase();
    const lineupModRef = ref(db, 'config/lineupModificationAllowed');
    const { t } = useTranslation();

    if (!isAdmin) return null; // Ensure that only admins can see this
    
    const handleToggle = async () => {
      const newStatus = !lineupModificationAllowed;
      await set(lineupModRef, newStatus);
      setLineupModificationAllowed(newStatus); // Update state using the setState function
    };

    const handleImportOfficial = () => {
       importOfficialLineUp(bands,canvasBands,setCanvasBands,setFeedback,t,setBands,setFullSubCanvases,initialBands,setInitialBands,setIsAdminMode);
    };
  
    return (
        <div className="admin-controls">
          <h3>{t('adminControl.title')}</h3>
          <button 
            className={`admin-button ${lineupModificationAllowed ? 'disable-mod' : 'enable-mod'}`} 
            onClick={handleToggle}
          >
            {lineupModificationAllowed ? t('adminControl.disableLineup') : t('adminControl.enableLineup')}
          </button>
          <button 
            className="admin-button calc-scores" 
            onClick={() => CalculateScores(initialBands)}
          >
            {t('adminControl.calculateScores')}
          </button>
          <button 
            className="admin-button reset-scores" 
            onClick={() => ResetScores(initialBands)}
          >
            {t('adminControl.resetScore')}
          </button>
          <button 
            className="admin-button importOfficial" 
            onClick={() => handleImportOfficial(initialBands,canvasBands,setCanvasBands,setFeedback,t)}
          >


            {t('adminControl.importOfficial')}
          </button>
          <button 
            className="admin-button disable-admin" 
            onClick={() => setIsAdminMode(false)}
          >
            {t('adminControl.turnOffAdmin')}
          </button>
        </div>
      );
}
export default AdminControl;
