import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import templateImage from './resources/template.webp';
import { useFetchData, useCanvasImage, useInitBands, handleOnDragEnd, useFetchUsers,useFetchEasterEggs } from './HooksAndHelpers';

import './css/MainPage.css';
import { auth } from './firebase';
import AvailableBands from './AvailableBands';
import Lineup from './Lineup';
import Leaderboard from './Leaderboard';
import { TOTAL_SUB_CANVAS,feedbackDuration } from './constants'
import { handleSignOut } from './SignIn';
import { getDatabase, ref, get } from "firebase/database"; // Firebase imports
import AdminControl from './AdminControl'
import { useTranslation } from 'react-i18next';
import Explanations from './Explanations';

function MainPage() {
  const { t } = useTranslation();
  const [bands, setBands] = useState([]);
  const [initialBands, setInitialBands] = useState([]);
  const [canvasImage] = useState(templateImage);
  const [isLoading, setIsLoading] = useState(false);
  const [canvasBands, setCanvasBands] = useState(Array(TOTAL_SUB_CANVAS).fill([]));
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [selectedUser, setSelectedUser] = useState(auth.currentUser ? auth.currentUser.displayName : null);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const currentUser = auth.currentUser;
  const [feedback, setFeedback] = useState({ message: '', type: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [newBandName, setNewBandName] = useState('');
  const UserList = useFetchUsers(currentUser.displayName);
  const [lineupModificationAllowed, setLineupModificationAllowed] = useState(true);
  const [fullSubCanvases, setFullSubCanvases] = useState([]);

  const easterEggs = useFetchEasterEggs();
  const db = getDatabase();
  const lineupModRef = ref(db, 'config/lineupModificationAllowed');

  useEffect(() => {
    get(lineupModRef).then((snapshot) => {
      if (snapshot.exists()) {
        setLineupModificationAllowed(snapshot.val());
      }
    });
  }, []);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setSelectedUser(user.displayName);
      } else {
        setSelectedUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (feedback) {
      const timeout = setTimeout(() => setFeedback(''), feedbackDuration); // hide after 3 seconds
      return () => clearTimeout(timeout); // Clear the timeout if the component unmounts
    }
  }, [feedback]);



  useFetchData(setIsLoading, setBands, setInitialBands, initialBands);

  useCanvasImage(setCanvasSize, canvasImage);

  useEffect(() => {
    setInitialized(false);
    setBands([...initialBands])
    setCanvasBands(Array(TOTAL_SUB_CANVAS).fill().map(() => []));
  }, [selectedUser]);

  useInitBands(initialized, setInitialized, bands, setBands, setCanvasBands, setLoading, selectedUser);

  if (loading) {
    return <div>{t('loading')}</div>;
  }


  return (
    <div className="main-container">
      {isLoading ? (
        <div>{t('loading')}</div>
      ) : (
        <div
          className="flex-container"
        >
          <DragDropContext
            onDragEnd={(result, provided) => {
              handleOnDragEnd(result, bands, setBands, canvasBands, setCanvasBands, searchTerm, selectedUser, isAdminMode, setFeedback,t,setFullSubCanvases,easterEggs);
              //setFeedback({ message: 'Action completed successfully!', type: 'success' });
            }}
          >
            <AvailableBands
              bands={bands}
              searchTerm={searchTerm}
              filter={filter}
              setFilter={setFilter}
              setSearchTerm={setSearchTerm}
              setNewBandName={setNewBandName}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              currentUser={currentUser}
              selectedUser={selectedUser}
              isAdminMode={isAdminMode}
              setBands={setBands}
              initialBands={initialBands}
              setCanvasBands={setCanvasBands}
              newBandName={newBandName}
              setIsAdminMode={setIsAdminMode}
              setInitialBands={setInitialBands}
              lineupModificationAllowed={lineupModificationAllowed}
              setFeedback={setFeedback}
            />
            <Lineup
              UserList={UserList}
              setSelectedUser={setSelectedUser}
              canvasSize={canvasSize}
              canvasImage={canvasImage}
              canvasBands={canvasBands}
              currentUser={currentUser}
              selectedUser={selectedUser}
              isAdminMode={isAdminMode}
              lineupModificationAllowed={lineupModificationAllowed}
              bands={bands}
              setBands={setBands}
              setCanvasBands={setCanvasBands}
              setFeedback={setFeedback}
              initialBands={initialBands}
              setFullSubCanvases={setFullSubCanvases}
              fullSubCanvases={fullSubCanvases}
            />
            {!lineupModificationAllowed ? <Leaderboard UserList={UserList} /> : null}
            {lineupModificationAllowed ?<Explanations feedback={feedback}/>:null}
            <AdminControl
              isAdmin={isAdminMode}
              lineupModificationAllowed={lineupModificationAllowed}
              setIsAdminMode={setIsAdminMode}
              setLineupModificationAllowed={setLineupModificationAllowed}
              initialBands={initialBands}
              canvasBands={canvasBands}
              setCanvasBands={setCanvasBands}
              setFeedback={setFeedback}
              setBands={setBands}
              bands={bands}
              setFullSubCanvases={setFullSubCanvases}
              setInitialBands={setInitialBands}
            />
          </DragDropContext>
        </div>
      )}
      <button className="google-signin signout-button" onClick={handleSignOut}>{t('signOut')}</button>
    </div>

  );
};

export default MainPage;
