import React, { useEffect, useState } from 'react';
import './i18n';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./MainPage/firebase";
import { SignIn } from './MainPage/SignIn';
import MainPage from './MainPage/MainPage';
import { useTranslation } from 'react-i18next';

function App() {
  const [user, setUser] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    // Detect browser language and set it
    const detectedLanguage = window.navigator.language || 'en';
    i18n.changeLanguage(detectedLanguage.split('-')[0]);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [i18n]);

  return (
    <div className="App">
      {user ?
        <MainPage />

        :
        <SignIn />}
    </div>
  );
}

export default App;
