// PaginationButtons.js
import React from 'react';
import { useTranslation } from 'react-i18next'; 
// Import any other required dependencies here...

const PaginationButtons = ({ currentPage, totalPages, setCurrentPage }) => {
  const { t } = useTranslation();
    return (
      <div className="pagination-buttons">
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === 1}
          className="pagination-button"
        >
           {t('pagination.previous')}
        </button>
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={currentPage >= totalPages}
          className="pagination-button"
        >
          {t('pagination.next')}
        </button>
      </div>
    );
  };
  

export default PaginationButtons;
