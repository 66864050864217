import { lineupOfficial } from './constants';
import { useTranslation } from 'react-i18next';

const Leaderboard = ({ UserList }) => {
  const { t } = useTranslation();
  const sortedUserList = [...UserList].filter(user => user.id !== lineupOfficial)
    .sort((a, b) => b.total - a.total);

  return (
    <div className="leaderboard-container">
      <h2>{t('explanations.bandTiersTitle')}</h2>
      <p dangerouslySetInnerHTML={{ __html: t('explanations.bandTiersIntroduction') }}></p>     
      <div className="tier-one">{t('explanations.tierOneText')}</div>
      <div className="tier-two">{t('explanations.tierTwoText')}</div>
      <div className="tier-three">{t('explanations.tierThreeText')}</div>
      <div className="tier-four">{t('explanations.tierFourText')}</div>
      <div className="tier-five">{t('explanations.tierFiveText')}</div>
      <h2>Leaderboard</h2>
      <table className='table'>
        <thead>
          <tr>
            <th>{t('leaderboard.position')}</th>
            <th>{t('leaderboard.user')}</th>
            <th>{t('leaderboard.points')}</th>
            <th>{t('leaderboard.SingleBonusPoints')}</th>
            <th>{t('leaderboard.BonusPoints')}</th>            
            <th>{t('leaderboard.Total')}</th>
          </tr>
        </thead>
        <tbody>
          {sortedUserList.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}</td>
              <td>{user.name}</td>
              <td>{user.points}</td>
              <td>{user.singleBonusPoints}</td>
              <td>{user.bonusPoints}</td>
              <td>{user.total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Leaderboard;
