import { useEffect, useState } from 'react';
import { maxBandsPerSubCanvas, TIER_DEFAULT, lineupOfficial } from './constants';
import { getDatabase, ref, set,get, remove, onValue } from "firebase/database";
import { query, collection, orderBy, getDocs } from 'firebase/firestore';
import { auth, db } from "./firebase";
import templateImage from './resources/template.webp';
import bandsFullText from './resources/bands_full.txt';
import { updateDatabase, fetchCanvasBandsData, updateUserBonus, setUserBonus,handleAddBand } from './FirebaseUtils'
import { ROWS_PER_CANVAS, TOTAL_SUB_CANVAS } from './constants'
import { filteredBands } from './AvailableBands'



export const useFetchData = (setIsLoading, setBands, setInitialBands) => {
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let bandsArray = [];
      try {
        const response = await fetch(bandsFullText);
        const bandsText = await response.text();
        bandsArray = bandsText.split('\n').map((band) => ({ name: band.trim(), id: band.trim() }));
      } catch (error) {
        console.error("Failed to fetch data from the local text file", error);
      }

      try {
        let bandQuery = query(collection(db, "userBands"), orderBy("name"));
        const userBandsSnapshot = await getDocs(bandQuery);
        const userBandsArray = userBandsSnapshot.docs.map(doc => ({ name: doc.data().name, id: doc.data().name }));

        // Combine and sort the bands
        const combinedBands = [...bandsArray, ...userBandsArray];
        combinedBands.sort((a, b) => a.name.localeCompare(b.name));
        setBands(combinedBands);
        setInitialBands([...combinedBands]);

      } catch (error) {
        console.error("Failed to fetch data from Firestore", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
};


export const useFetchEasterEggs = () => {
  const [easterEggs, setEasterEggs] = useState({});
  const db = getDatabase();

  useEffect(() => {
    const fetchData = async () => {
      const easterEggRef = ref(db, 'config/easterEggs');
      const snapshot = await get(easterEggRef);

      if (snapshot.exists()) {
        setEasterEggs(snapshot.val());
      }
    };

    fetchData();
  }, [db]); // The db reference doesn't change but it's good practice to include dependencies

  return easterEggs;
};


export const useInitBands = (
  initialized,
  setInitialized,
  bands,
  setBands,
  setCanvasBands,
  setLoading,
  selectedUser
) => {
  // Declare these variables within the function's scope
  const [areBandsInitialized, setAreBandsInitialized] = useState(false);

  useEffect(() => {
    // Reset areBandsInitialized when selectedUser changes
    setAreBandsInitialized(false);
  }, [selectedUser]);

  useEffect(() => {
    if (initialized || bands.length === 0 || areBandsInitialized) return;

    setAreBandsInitialized(true);

    fetchCanvasBandsData(selectedUser, bands)
      .then(data => {
        setCanvasBands(data.newCanvasBands);
        setBands(data.remainingBands);
        setLoading(false);
        setInitialized(true);
      });

  }, [bands, selectedUser]);
};

const findBandByName = (name, allBands) => {
  return allBands.find(band => band.name === name);
};


export const handleOnDragEnd = (result, bands, setBands, canvasBands, setCanvasBands, searchTerm, selectedUser, isAdminMode, setFeedback, t, setFullSubCanvases,easterEggs) => {
  const updatedSubCanvasIndexes = new Set();
  const currentUser = auth.currentUser;
  const checkForEasterEgg = (id) => {
    return easterEggs[id];
  }


  if (currentUser.displayName !== selectedUser && !isAdminMode) {
    return;
  }



  const startDroppableId = result.source.droppableId;
  const endDroppableId = result.destination ? result.destination.droppableId : null;

  const startFromBands = startDroppableId === "availableBands";
  const endOnBands = endDroppableId === "availableBands";
  if (endDroppableId === null && startFromBands) {
    return
  }
  const sourceArray = startFromBands ? filteredBands.filter(band => band.name.toLowerCase().startsWith(searchTerm.toLowerCase())) : [...canvasBands[Number(startDroppableId.split('-')[1])]];
  const [removed] = sourceArray.splice(result.source.index, 1);

  let sourceCopy = [...canvasBands];

  const clearSubCanvas = (db, user, subCanvasIndex) => {
    let promises = [];
    for (let i = 0; i < maxBandsPerSubCanvas[subCanvasIndex % 4]; i++) {
      const promise = remove(ref(db, `users/${user}/subcanvas${subCanvasIndex}/spot${i}`));
      promises.push(promise);
    }
    return Promise.all(promises);
  };

  if (startDroppableId.startsWith("subcanvas-") && (endDroppableId === null || endOnBands)) {
    setBands(prev => {
      const updatedBands = [...prev, removed];
      return updatedBands.sort((a, b) => a.id.localeCompare(b.id));
    });
    if (!startFromBands) {
      sourceCopy[Number(startDroppableId.split('-')[1])] = sourceArray;
      setCanvasBands(sourceCopy);


      const easterEggData = checkForEasterEgg(removed.id);
      if (easterEggData) {
        updateUserBonus(selectedUser, -easterEggData.points)
      }
      updatedSubCanvasIndexes.add(Number(startDroppableId.split('-')[1]));
      setFeedback({ message: removed.id + t('feedbacks.bandRemoved'), type: 'success' });
    }

  }

  else if (startDroppableId.startsWith("subcanvas-") && endDroppableId.startsWith("subcanvas-")) {
    const startSubCanvasIndex = Number(startDroppableId.split('-')[1]);
    const endSubCanvasIndex = Number(endDroppableId.split('-')[1]);
    const columnNumber = endSubCanvasIndex % ROWS_PER_CANVAS;
    updatedSubCanvasIndexes.add(Number(startDroppableId.split('-')[1]));
    if (startSubCanvasIndex === endSubCanvasIndex) { // If the source and the destination sub-canvases are the same
      sourceArray.splice(result.destination.index, 0, removed);
      sourceCopy[startSubCanvasIndex] = sourceArray;
      setCanvasBands(sourceCopy);
      setFeedback({ message: removed.id + t('feedbacks.bandReorganised'), type: 'success' });
    }
    else if (sourceCopy[endSubCanvasIndex].length < maxBandsPerSubCanvas[columnNumber]) {
      sourceCopy[endSubCanvasIndex] = [...sourceCopy[endSubCanvasIndex], removed];
      sourceCopy[startSubCanvasIndex] = sourceArray;
      setCanvasBands(sourceCopy);
      setFeedback({ message: removed.id + t('feedbacks.bandReorganised'), type: 'success' });
      updatedSubCanvasIndexes.add(Number(endDroppableId.split('-')[1]));
    }
    else {
      setFeedback({ message: removed.id + t('feedbacks.noMoreSpace'), type: 'warning' });
    }
  }

  else if (startFromBands && endDroppableId.startsWith("subcanvas-")) {
    const endSubCanvasIndex = Number(endDroppableId.split('-')[1]);
    const columnNumber = endSubCanvasIndex % ROWS_PER_CANVAS;
    const availableSpace = maxBandsPerSubCanvas[columnNumber] - sourceCopy[endSubCanvasIndex].length;
    if (availableSpace > 0) {
      const easterEggData = checkForEasterEgg(removed.id);
      updatedSubCanvasIndexes.add(Number(endDroppableId.split('-')[1]));
      if (easterEggData) {
        if (easterEggData.swap) {
          const bandToSwapWith = findBandByName(easterEggData.swap, bands);
          if (bandToSwapWith) {
            sourceCopy[endSubCanvasIndex] = [...sourceCopy[endSubCanvasIndex], bandToSwapWith];
            setCanvasBands(sourceCopy);
            setBands(bands.filter(band => band.id !== bandToSwapWith.id));
            updateUserBonus(selectedUser, easterEggData.points)
          }
        }
        else {
          sourceCopy[endSubCanvasIndex] = [...sourceCopy[endSubCanvasIndex], removed];
          setCanvasBands(sourceCopy);
          setBands(bands.filter(band => band.id !== removed.id));
          updateUserBonus(selectedUser, easterEggData.points)
        }

        setFeedback({ message: easterEggData.message, type: 'info' });
      }
      else {
        sourceCopy[endSubCanvasIndex] = [...sourceCopy[endSubCanvasIndex], removed];
        setCanvasBands(sourceCopy);
        setBands(bands.filter(band => band.id !== removed.id));
        setFeedback({ message: removed.id + t('feedbacks.bandAdded'), type: 'success' });
      }
    }
    else {
      setFeedback({ message: removed.id + t('feedbacks.noMoreSpace'), type: 'warning' });
    }
  }

  if (selectedUser != null) {
    const db = getDatabase();
    //const updatedCanvasBands = sourceCopy; // use the updated sourceCopy array

    updatedSubCanvasIndexes.forEach(subCanvasIndex => {
      clearSubCanvas(db, selectedUser, subCanvasIndex);
      const subCanvasBands = sourceCopy[subCanvasIndex];
      subCanvasBands.forEach((band, spotIndex) => {
        updateDatabase(selectedUser, subCanvasIndex, spotIndex, band, TIER_DEFAULT);
      });
    });
    const updatedFullSubCanvases = sourceCopy.map((subCanvasBands, index) => subCanvasBands.length >= maxBandsPerSubCanvas[index % maxBandsPerSubCanvas.length]);
    setFullSubCanvases(updatedFullSubCanvases);
  }

};

export function handleRandomFill(bands, canvasBands, selectedUser, setFeedback, t, setFullSubCanvases) {
  const isConfirmed = window.confirm(t('messages.confirmRandom'))
  let availableBands = JSON.parse(JSON.stringify(bands));
  let newCanvasBands = JSON.parse(JSON.stringify(canvasBands));
  if (isConfirmed) {
    for (let i = 0; i < newCanvasBands.length; i++) {
      // While the current subcanvas hasn't reached its max, and there are still bands available:
      while (newCanvasBands[i].length < maxBandsPerSubCanvas[i % ROWS_PER_CANVAS] && availableBands.length > 0) {

        let randomIndex = Math.floor(Math.random() * availableBands.length);
        const selectedBand = availableBands[randomIndex];

        newCanvasBands[i].push(selectedBand);

        // Remove the band from availableBands
        availableBands.splice(randomIndex, 1);

        // Update the database (consider batching these updates for efficiency)
        if (selectedUser) {
          updateDatabase(selectedUser, i, newCanvasBands[i].length - 1, selectedBand, TIER_DEFAULT);

        }
      }
    }
    setFeedback({ message: t('feedbacks.randomized'), type: 'success' });
  }
  const updatedFullSubCanvases = newCanvasBands.map((subCanvasBands, index) => subCanvasBands.length >= maxBandsPerSubCanvas[index % maxBandsPerSubCanvas.length]);
  setFullSubCanvases(updatedFullSubCanvases);
  return [availableBands, newCanvasBands];

}  

export async function importOfficialLineUp(bands, canvasBands, setCanvasBands, setFeedback, t, setBands, setFullSubCanvases, initialBands, setInitialBands, setIsAdminMode) {
  let availableBands = JSON.parse(JSON.stringify(bands));
  let newCanvasBands = JSON.parse(JSON.stringify(canvasBands));
  let UnFoundBandList = "";

  try {
    const response = await fetch(process.env.PUBLIC_URL + '/official.xml');
    const data = await response.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(data, "text/xml");

    // Process the XML file
    const Stages = xmlDoc.getElementsByTagName("Stage");
    for (let i = 0; i < Stages.length; i++) {
      const stage = Stages[i];
      const subcanvasIndex = parseInt(stage.getAttribute('subcanvas'), 10);
      newCanvasBands[subcanvasIndex] = newCanvasBands[subcanvasIndex] || [];
      const artists = stage.getElementsByTagName("Artist");

      for (let j = 0; j < artists.length; j++) {
        const artist = artists[j];
        const spot = parseInt(artist.getAttribute('spot'), 10);
        const name = artist.textContent.trim();

        const bandIndex = availableBands.findIndex(b => b.name.toLowerCase() === name.toLowerCase());
        if (bandIndex === -1) {
          // If band is not found, try to add it
          const addResult = await handleAddBand(setBands, initialBands, setInitialBands, name, setIsAdminMode, setFeedback, t);
          if (!addResult) {
            // If the band could not be added, log and continue to the next artist
            UnFoundBandList += `${name} : ${subcanvasIndex} : ${spot}\n`;
            continue;
          }
        }
        
        // Retrieve the band again, as it might have been added
        const updatedBandIndex = availableBands.findIndex(b => b.name.toLowerCase() === name.toLowerCase());
        if (updatedBandIndex !== -1) {
          const selectedBand = availableBands[updatedBandIndex];
          newCanvasBands[subcanvasIndex][spot] = selectedBand;
          availableBands.splice(updatedBandIndex, 1);
          updateDatabase(lineupOfficial, subcanvasIndex, spot, selectedBand, TIER_DEFAULT);
        }
      }
    }

    console.log(UnFoundBandList);
    setFeedback({ message: t('feedbacks.importedSuccessfully'), type: 'success' });

    const updatedFullSubCanvases = newCanvasBands.map((subCanvasBands, index) => subCanvasBands.length >= maxBandsPerSubCanvas[index % maxBandsPerSubCanvas.length]);
    setFullSubCanvases(updatedFullSubCanvases);
    setCanvasBands(newCanvasBands)
    setBands(availableBands)

  } catch (error) {
    setFeedback({ message: t('feedbacks.errorReadingFile'), type: 'error' });
    console.error("Error fetching XML file:", error);
  }
}


export const useCanvasImage = (setCanvasSize) => {
  useEffect(() => {
    const img = new Image();
    img.src = templateImage;
    img.onload = function () {
      setCanvasSize({ width: this.width, height: this.height });
    };
  }, [setCanvasSize]);
};

export const useFetchUsers = (currentUser) => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const usersRef = ref(db, 'users');

    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      let users = [];

      for (let userId in data) {
        users.push({
          id: userId,
          name: userId,
          points: data[userId].points || 0,
          bonusPoints: data[userId].bonusPoints || 0,
          singleBonusPoints: data[userId].singleBonusPoints||0,
          total: ((data[userId].points || 0) + (data[userId].bonusPoints||0)+(data[userId].singleBonusPoints||0)) || 0
        });
       
      }

      // Sort users by points (in descending order)
      users.sort((a, b) => b.points - a.points);

      // Check if the current user is in the list
      const currentUserData = users.find(user => user.id === currentUser);

      if (currentUserData) {
        // If the current user is in the list, move them to the top
        users = users.filter(user => user.id !== currentUser);
        users.unshift(currentUserData);
      } else if (currentUser) {
        // If the current user is not in the list, add them to the userList and the database
        users.unshift({
          id: currentUser,
          name: currentUser,
          points: 0,
          total: 0
        });

        // Create the user in the database
        set(ref(db, `users/${currentUser}`), {
          points: 0,
        });
      }

      // Check if "Line up officielle" is in the list
      const officialLineup = users.find(user => user.id === lineupOfficial);
      if (officialLineup) {
        // Remove "Line up officielle" from its current position
        users = users.filter(user => user.id !== lineupOfficial);

        // Insert "Line up officielle" as the second user
        users.splice(1, 0, officialLineup);
      }

      setUserList(users);
    });

  }, [currentUser]);

  return userList;
};



export function clearLineupForUser(userId, setCanvasBands, setFeedback, t) {
  const isConfirmed = window.confirm(t('messages.confirmClear'))
  if (!isConfirmed) return;

  const db = getDatabase();
  const promises = Array(TOTAL_SUB_CANVAS).fill().map((_, subCanvasIndex) => {
    const spotRef = ref(db, `users/${userId}/subcanvas${subCanvasIndex}`);
    return set(spotRef, []);
  });

  Promise.all(promises)
    .then(() => {
      setCanvasBands(Array(TOTAL_SUB_CANVAS).fill([]));  // Clear the local state for immediate feedback
      setUserBonus(userId, 0);
      setFeedback({ message: t('feedbacks.lineupCleared'), type: 'success' });
    })
    .catch(err => {
      console.error(`Error clearing lineup for user ${userId}: ${err.message}`);
    });
};



export const checkSpecialCommand = (command, setIsAdminMode) => {
  switch (command) {
    case "/adminMode":
      setIsAdminMode(prev => !prev);
      return true;
    default:
      if (command.startsWith('/')) {
        return true;
      }
      return false;
  }
};








