import {getDatabase,set,ref, remove,onValue,get,runTransaction } from "firebase/database";
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import {checkSpecialCommand} from './HooksAndHelpers';
import { maxBandsPerSubCanvas,TOTAL_SUB_CANVAS,ROWS_PER_CANVAS,maxLenBandName } from './constants';

export const clearSubCanvas = (db, user, subCanvasIndex, maxBandsPerSubCanvas) => {
  let promises = [];
  for(let i = 0; i < maxBandsPerSubCanvas[subCanvasIndex % ROWS_PER_CANVAS]; i++) {
    const promise = remove(ref(db, `users/${user.displayName}/subcanvas${subCanvasIndex}/spot${i}`));
    promises.push(promise);
  }
  return Promise.all(promises);
};


export const handleAddBand = async (setBands, initialBands, setInitialBands, newBandName,setIsAdminMode,setFeedback,t) => {

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Check for the special band name to toggle admin mode
  if (checkSpecialCommand(newBandName,setIsAdminMode)) {
    return false; // Early return if a special command was executed
  }
  else if(newBandName.length>maxLenBandName){
    setFeedback({ message:newBandName+ t('feedbacks.NameTooLong'), type: 'warning' })
    return false;
  }

  newBandName = capitalizeFirstLetter(newBandName);
  const bandExists = initialBands.some(band => band.name.toLowerCase() === newBandName.toLowerCase());

  if (!bandExists) {
    const newBandId = newBandName.replace(/\s/g, '');

    const db = getFirestore();
    await setDoc(doc(db, "userBands", newBandId), {
      name: newBandName,
      id: newBandId,
    });
    
    setBands(prevBands => {
      const combinedBands = [...prevBands, { id: newBandId, name: newBandName }];
      combinedBands.sort((a, b) => a.name.localeCompare(b.name));
      return combinedBands;
    });

    setInitialBands(prevInitialBands => {
      const combinedInitialBands = [...prevInitialBands, { id: newBandId, name: newBandName }];
      combinedInitialBands.sort((a, b) => a.name.localeCompare(b.name));
      return combinedInitialBands;
    });
    setFeedback({ message:newBandName+ t('feedbacks.bandCreated'), type: 'success' });
    return true;
  }
  setFeedback({ message:newBandName+ t('feedbacks.bandExists'), type: 'warning' });

  return false;
};

export const updateDatabase = (selectedUser, subCanvasIndex, spotIndex, band,tier) => {
  const db = getDatabase();
  if (selectedUser){
      set(ref(db, `users/${selectedUser}/subcanvas${subCanvasIndex}/spot${spotIndex}`), {
          band: band.name,
          tier: tier,
      });
  }
};

export const updateBonus = (selectedUser, bonusPoints,nodeRef) => {
  const db = getDatabase();

  if (selectedUser) {
    const bonusPointsRef = ref(db, `users/${selectedUser}/${nodeRef}`);
    
    runTransaction(bonusPointsRef, (currentPoints) => {
      if (currentPoints === null) {
        return bonusPoints; // if there's no previous value, set the initial bonus points
      }
      return currentPoints + bonusPoints; // add the new bonus points to the current value
    });
  }
};
export const updateUserBonus = (selectedUser, bonusPoints) => {
  const nodeRef ='bonusPoints'
  return updateBonus(selectedUser,bonusPoints,nodeRef) 
};

export const updateUserSingleBonus = (selectedUser, bonusPoints) => {
  const nodeRef ='singleBonusPoints'
  return updateBonus(selectedUser,bonusPoints,nodeRef) 
};


export const setPoints = (selectedUser,bonusPoints,nodeRef) => {
  const db = getDatabase();
  
  if (selectedUser){
      set(ref(db, `users/${selectedUser}/${nodeRef}`), bonusPoints);
  }
};

export const setUserBonus = (selectedUser,bonusPoints) => {
  const nodeRef = 'bonusPoints'
  setPoints(selectedUser,bonusPoints,nodeRef)
};
export const setSingleBonus = (selectedUser,bonusPoints) => {
  const nodeRef='singleBonusPoints';
  setPoints(selectedUser,bonusPoints,nodeRef)
};
export const setUserPoints = (selectedUser,bonusPoints) => {
  const nodeRef='points';
  setPoints(selectedUser,bonusPoints,nodeRef)
};


export const fetchCanvasBandsData = async (selectedUser, initialBands) => {
  const db = getDatabase();
  let newCanvasBands = Array(TOTAL_SUB_CANVAS).fill().map(() => []);
  let bandsCopy = [...initialBands];

  let promises = [];
  newCanvasBands.forEach((_, subCanvasIndex) => {
      for (let spotIndex = 0; spotIndex < maxBandsPerSubCanvas[subCanvasIndex %ROWS_PER_CANVAS]; spotIndex++) {
          const spotRef = ref(db, `users/${selectedUser}/subcanvas${subCanvasIndex}/spot${spotIndex}`);
          const promise = new Promise(resolve => {
              onValue(spotRef, (snapshot) => {
                  const data = snapshot.val();
                  if (data && data.band) {
                      const bandIndex = bandsCopy.findIndex(band => band.name === data.band);
                      if (bandIndex >= 0) {
                          const band = { 
                              ...bandsCopy[bandIndex], 
                              tier: data.tier // fetch tier from the database and assign it to the band
                          };
                          newCanvasBands[subCanvasIndex].push(band);
                          bandsCopy.splice(bandIndex, 1);
                      }
                  }
                  resolve();
              });
          });
          promises.push(promise);
      }
  });

  await Promise.all(promises);
  return { newCanvasBands, remainingBands: bandsCopy };
}


