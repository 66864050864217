import { useTranslation } from 'react-i18next';

function Explanations({ feedback }) {
  const { t } = useTranslation();

  return (
    <div className="explanations-container">
      <div className={`feedback-container ${feedback.message ? 'visible' : ''} ${feedback.type}`}>
        {feedback.type === 'success' ? '✅' : feedback.type === 'warning' ? '⚠️' :feedback.type === 'info'?'😈': ''} {feedback.message}
      </div>
      <h2>{t('explanations.title')}</h2>
      <p>{t('explanations.introduction')}</p>


      <h3>{t('explanations.pointOne')}</h3>
      <ul>
        <li>{t('explanations.pointOneA')}</li>

        <li>{t('explanations.pointOneC')}</li>
        <li>{t('explanations.pointOneD')}</li>
      </ul>

      <h3>{t('explanations.pointTwo')}</h3>
      <ul>
        <li>{t('explanations.pointTwoA')}</li>
        <li>{t('explanations.pointTwoB')}</li>
        <li>{t('explanations.pointTwoC')}</li>
      </ul>

      <h3>{t('explanations.pointThree')}</h3>
      <ul>
        <li>{t('explanations.pointThreeA')}</li>

        <li>{t('explanations.pointThreeC')}</li>
      </ul>
      <h1></h1>
    </div>
  );
};

export default Explanations;
