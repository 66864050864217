import templateImage from './resources/template.webp';
export const COLUMNS_PER_CANVAS = 6;
export const TOTAL_SUB_CANVAS = 24;
export const ROWS_PER_CANVAS = 4;

export const initialState = {
  bands: [],
  canvasImage: templateImage,
  isLoading: false,
  canvasBands: Array(TOTAL_SUB_CANVAS).fill([]),
  loading: true,
  initialized: false,
  filter: 'all',
  searchTerm: '',
  canvasSize: { width: 0, height: 0 }
};

export const maxBandsPerSubCanvas = [5, 9, 9, 8];

export const lineupOfficial = 'Line-up Officielle';

export const TIER_DEFAULT = 0;
export const TIER_ONE = 1;
export const TIER_TWO = 2;
export const TIER_THREE = 3;
export const TIER_FOUR = 4;
export const TIER_FIVE= 5;

export const POINTS_EXISTS = 1;
export const POINTS_GOOD_STAGE = 2;
export const POINTS_GOOD_DAY = 5;
export const POINTS_GOOD_HOUR=7;
export const POINTS_ALL_GOOD = 10;
export const POINTS_BAND_ALONE=10;

export const MaxBandsPerPage = 50;
export const MinBandsPerPage = 10;

export const maxLenBandName = 70;
export const feedbackDuration = 5000;
export const bandBoxSize=40;
export const MarginOffset=430;

export const selectedMoreThanOnce="selectedMoreThanOnce"



